<template>
	<div class="df fdc bcf1 oh" style="height: 100%;">
		<general-header title="注册账户"></general-header>
		<div class="f1 pt30">
			<div class="df m0a bsbb aifs jcc" style="width: 1200px; height: 100%;">
				<div class="p20 br5 bcff" style="width: 380px;">
					<div class="mb10 tac">
						<h3 class="c3 mb5">欢迎注册使用企业管理系统</h3>
						<p class="c6 fs12">已注册账户！ <span class="cb7a06a cp" @click="$utils.toRoute('/login')">去登录</span></p>
					</div>
					<el-form ref="regForm" :model="reg_form" :rules="rules" @submit.prevent status-icon>
						<el-form-item label="登录账户" prop="account">
							<el-input type="text" v-model="reg_form.account" autocomplete="off" placeholder="请输入手机号码 OR 电子邮箱" clearable></el-input>
						</el-form-item>
						<el-form-item label="登录密码" prop="password">
							<el-input type="password" v-model="reg_form.password" autocomplete="off" placeholder="请输入登录密码" clearable></el-input>
						</el-form-item>
						<el-form-item label="确认密码" prop="verify_password">
							<el-input type="password" v-model="reg_form.verify_password" autocomplete="off" placeholder="请输入确认密码" clearable></el-input>
						</el-form-item>
						<el-form-item label="验证码" prop="code">
							<el-input type="text" v-model="reg_form.code" autocomplete="off" placeholder="请输入手机 OR 邮箱验证码" clearable>
								<el-button type="primary" slot="append" @click="getCode" :disabled="get_disabled">{{code_text}}</el-button>
							</el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('regForm')">立即注册</el-button>
							<p class="fs12 c6">忘记账户密码？ <span class="cff0000 cp" @click="$utils.toRoute('/forgot')">去找回</span></p>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<copy-right></copy-right>
		<v-code :show="show_vode" @success="sendCode" @close="show_vode = false"></v-code>
	</div>
</template>

<style>

</style>

<script>
	import GeneralHeader from '@/components/general-header';
	import CopyRight from '@/components/general-copyright';
	import VCode from "vue-puzzle-vcode";

	const mobile_reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
	const email_reg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

	export default {
		components: {
			GeneralHeader,
			CopyRight,
			VCode
		},
		computed: {
			get_disabled () {
				const { account } = this.reg_form;
				if (!account) return true;
				if (!email_reg.test(account) && !mobile_reg.test(account)) return true;
				return false;
			}
		},
		methods: {
			getCode () {
				if (this.time) return false;
				const { account } = this.reg_form;
				if (!account) return this.$message.error('请输入正确的手机号码 OR 电子邮箱！');
				this.show_vode = true;
			},
			clearTimer (text = '重新获取') {
				clearInterval(this.timer);
				this.code_text = text;
				this.timer = null;
				this.time = 0;
			},
			async sendCode () {
				this.time = 99;
				this.show_vode = false;
				this.timer = setInterval(() => {
					if (!this.time) return this.clearTimer('重新获取');
					this.code_text = `${this.time} 秒后重新获取`;
					this.time--
				}, 1000);
				// this.$message.error('调用接口发送验证码！');	
				const { account } = this.reg_form;
				if (!account) return this.$message.error('请输入正确的手机号码 OR 电子邮箱！');				
				const res = await this.$http.post(this.$api.URI_CAPTCHA, { type: 'register', account });
				const { code, msg } = res.data;
				if (code != 0) {
					this.clearTimer('点击获取');
					return this.$message.error(msg);
				}
				this.$message.success(msg);
			},
			submitForm (ref) {
				this.$refs[ref].validate(async (valid) => {
					if (!valid) return this.$message.error('填写表单有误！');
					const res = await this.$http.post(this.$api.URI_REGISTER, this.reg_form);
					const { code, msg } = res.data;
					if (code != 0) return this.$message.error(msg);
					this.$message({
						type: 'success',
						duration: 1000,
						message: msg,
						onClose: () => {
							this.$utils.toRoute('/login');
						}
					});
				});
			}
		},
		data() {
			const checkAccount = (rule, value, callback) => {
				if (value && !email_reg.test(value) && !mobile_reg.test(value)) return callback(new Error('请输入正确的手机号码 OR 电子邮箱'));
				return callback();
			}
			const checkPassword = (rule, value, callback) => {
				const { password, verify_password } = this.reg_form;
				// 两者都有值时才验证对比
				if (password && verify_password && password != verify_password) {
					const { field } = rule;
					if (field === 'password') return callback(new Error('请输入登录密码与确认密码不一致'));
					if (field === 'verify_password') return callback(new Error('请输入确认密码与登录密码不一致'));
				}
				this.$refs['regForm'].clearValidate(['password', 'verify_password']);
				return callback();
			}
			return {
				time: 0,
				timer: null,
				show_vode: false,
				code_text: '点击获取',
				reg_form: {},
				rules: {
					account: [
						{ required: true, trigger: 'blur', message: '请输入手机号码 OR 电子邮箱！' },
						{ validator: checkAccount, trigger: ['blur', 'change'] }
					],
					password: [
						{ required: true, trigger: ['blur', 'change'], message: '请输入登录密码！' },
						{ validator: checkPassword, trigger: ['blur', 'change'] }
					],
					verify_password: [
						{ required: true, trigger: ['blur', 'change'], message: '请输入确认密码！' },
						{ validator: checkPassword, trigger: ['blur', 'change'] }
					],
					code: [{required: true, trigger: ['blur', 'change'], message: '请输入手机号码 OR 电子邮箱验证码！'}]
				}
			};
		}
	}
</script>
